import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths } from 'utils/path'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticasMetadata } from 'site/src/data/graphql/graphql-types'
import {
  MagazineContentWrapper,
  MagazineItemWrapper
} from 'site/src/modules/magazine/component/magazine-content-wrapper.component'
import {
  Item,
  MagazineItem
} from 'site/src/modules/magazine/component/magazine-item.component'
import { Body, Col, Grid, Row } from 'atomic'
import { graphql } from 'gatsby'
import React from 'react'

export interface FleuryMagazinesTemplateProps {
  list: Item[]
}

class FleuryMagazinesTemplate extends React.Component<
  PageProps<FleuryMagazinesTemplateProps>
> {
  render() {
    const staticInfo = this.props.data.cosmicjsInformacoesEstaticas
      .metadata as CosmicjsInformacoesEstaticasMetadata
    return (
      <ClientDoctorSelectContext.Provider
        value={{
          clientUrl: appPaths.fleuryMagazines.path.clientUrl,
          doctorUrl: appPaths.medicalMagazines.path.doctorUrl
        }}
      >
        <IndexLayout location={this.props.location}>
          <SEO
            jsonld={getListJsonLd(
              this.props.pageContext.list.map(item => ({
                url: item.downloadUrl
              }))
            )}
            socialMedia={{
              canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
              title: staticInfo.seo.titulo,
              image: staticInfo.seo.imagem.url,
              imageAlt: staticInfo.seo.imagemAlternativa,
              description: staticInfo.seo.descricao
            }}
          />
          <Grid>
            <TitleWithBreadcrumbRow title={staticInfo.titulo}>
              <Body>{staticInfo.descricao}</Body>
            </TitleWithBreadcrumbRow>
            <Row>
              <Col xs={12}>
                <MagazineContentWrapper>
                  {this.props.pageContext.list &&
                    this.props.pageContext.list.map((item, index) => (
                      <MagazineItemWrapper
                        id={`magazine-item-wrapper-${index}`}
                        first={index === 0}
                        key={item.title}
                      >
                        <MagazineItem
                          id={`magazine-item-${index}`}
                          item={item}
                          first={index === 0}
                        />
                      </MagazineItemWrapper>
                    ))}
                </MagazineContentWrapper>
              </Col>
            </Row>

            <LargeSeparatorRow />
          </Grid>
        </IndexLayout>
      </ClientDoctorSelectContext.Provider>
    )
  }
}

export default FleuryMagazinesTemplate

export const query = graphql`
  query RevistasFleury {
    site {
      siteMetadata {
        siteUrl
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "revistas-fleury" }) {
      metadata {
        titulo
        descricao
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
  }
`
